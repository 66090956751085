import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  addTicket,
  getAllTickets,
  getBookings,
  logoutUser,
  updateProfile,
} from "../../../redux/user/userSlice";
import AwardSectionStyle2 from "../../Section/AwardSection/AwardSectionStyle2";
import useAuthToken from "../../../hooks/useAuthToken";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";

export default function AppointmentSectionStyle3({ title }) {
  const dispatch = useDispatch();

  const user = useSelector((state) => state?.user?.user?.user || {});
  const bookings = useSelector((state) => state.user.bookings);
  const tickets = useSelector((state) => state.user.tickets?.data || []);
  const token = useAuthToken();
  const user_id = user?.id;
  const navigate = useNavigate();

  const [activeCategory, setActiveCategory] = useState("profile"); // New state to track active category

  const [showModal, setShowModal] = useState(false); // State to manage modal visibility
  const [subject, setSubject] = useState("");
  const [message, setMessage] = useState("");
  const [status, setStatus] = useState("");
  const [name, setName] = useState(user?.name || "");
  const [phone, setPhone] = useState(user?.phone || "");
  const [email, setEmail] = useState(user?.email || "");
  const [gender, setGender] = useState(user?.gender || "");
  const [address, setAddress] = useState(
    typeof (user?.family_detials) == 'object' ? user?.family_detials?.primaryInsured?.address : user?.family_detials ? JSON.parse(user?.family_detials)?.['primaryInsured']['address'] : ""
  );
  const [dob, setDob] = useState(user.dob);
  const [prevDob, setPrevDob] = useState(user.dob);
  const [updated, setUpdated] = useState(false);

  const handleDobChange = (e) => {
    let input = e.target.value;

    // Check if the user is backspacing by comparing current and previous input length
    if (input.length < prevDob.length) {
      setDob(input); // Allow backspacing without formatting
    } else {
      // Remove non-numeric characters
      input = input.replace(/\D/g, "");

      // Format as DD/MM/YYYY
      if (input.length >= 2) input = input.slice(0, 2) + "/" + input.slice(2);
      if (input.length >= 5) input = input.slice(0, 5) + "/" + input.slice(5, 9);
      if (input.length > 10) input = input.slice(0, 10); // Limit to DD/MM/YYYY format length

      setDob(input);
    }

    setPrevDob(input); // Update previous input state
  };

  const handleNewTicketClick = () => {
    setShowModal(true);
  };

  const phoneNumber = user?.partner_details?.phone; // Make sure this is the correct phone number format

  const handleCloseModal = () => {
    setShowModal(false);
  };
  const handleProfileUpdate = () => {
    const formData = new FormData();
    formData.append("name", name);
    formData.append("phone", phone);
    formData.append("email", email);
    formData.append("gender", gender);
    formData.append("dob", dob);
    formData.append("user_id", user_id);
    formData.append("token", token);

    dispatch(updateProfile(formData))
      .then((res) => {
        // Handle the response or any other actions needed
        console.log("Profile updated successfully");
        setUpdated(true);

        // Hide the message after 2 seconds
        setTimeout(() => {
          setUpdated(false);
        }, 3000); // 2000 ms = 2 seconds
      })
      .catch((err) => {
        // Handle errors here
        console.error("Failed to update profile", err);
      });
  };

  const handleSubmitTicket = (subject, message, status) => {
    const formData = new FormData();
    formData.append("subject", subject);
    formData.append("message", message);
    formData.append("user_id", user_id);
    formData.append("token", token);

    dispatch(addTicket(formData)).then((res) => {
      // Optionally fetch tickets again after adding a new ticket to ensure state is up to date
      dispatch(getAllTickets(formData));
      setSubject("");
      setMessage("");
    });
  };

  const logout = () => {
    // if (user?.success && user?.user?.token) {
    dispatch(logoutUser());
    navigate("/");
    // }
    // setShowPopup(true);
  };

  useEffect(() => {
    dispatch(getBookings(token));
  }, [dispatch, token, updated]);

  useEffect(() => {
    if (user_id) {
      const formData = new FormData();
      formData.append("user_id", user_id);
      formData.append("token", token);

      dispatch(getAllTickets(formData));
    }
  }, [dispatch, user_id]);



  if (!user) {
    window.location.replace("/");
  }

  return (
    <div className="container">
      <form action="#" className="cs_appointment_form cs_style_1">
        <div className="cs_appointment_form_head">
          <h2 className="cs_fs_40 mb-0">
            {title}
            <div className="cs_fs_16">{user?.customer_id}</div>
          </h2>
          <div className="cs_appointment_categories">
            <a
              className="cs_appointment_category"
              onClick={() => setActiveCategory("profile")}
            >
              <span
                style={{
                  color: activeCategory === "profile" ? "#D2538C" : "",
                  borderBottom:
                    activeCategory === "profile" ? "1px solid #D2538C" : "",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Profile
              </span>
            </a>
            {user?.insurance_type == 'FAMILY' &&
              <a
                className="cs_appointment_category"
                onClick={() => setActiveCategory("family")}
              >
                <span
                  style={{
                    color: activeCategory === "family" ? "#D2538C" : "",
                    borderBottom:
                      activeCategory === "family"
                        ? "1px solid #D2538C"
                        : "",
                    fontSize: "15px",
                    fontWeight: "bold",
                    letterSpacing: "0.5px",
                  }}
                >
                  Family Members
                </span>
              </a>
            }
            <a
              className="cs_appointment_category"
              onClick={() => setActiveCategory("subscriptions")}
            >
              <span
                style={{
                  color: activeCategory === "subscriptions" ? "#D2538C" : "",
                  borderBottom:
                    activeCategory === "subscriptions"
                      ? "1px solid #D2538C"
                      : "",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Subscriptions
              </span>
            </a>
            <a
              className="cs_appointment_category"
              onClick={() => setActiveCategory("tickets")}
            >
              <span
                style={{
                  color: activeCategory === "tickets" ? "#D2538C" : "",
                  borderBottom:
                    activeCategory === "tickets" ? "1px solid #D2538C" : "",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Tickets
              </span>
            </a>
            <a
              className="cs_appointment_category"
              onClick={() => setActiveCategory("salespartner")}
            >
              <span
                style={{
                  color: activeCategory === "salespartner" ? "#D2538C" : "",
                  borderBottom:
                    activeCategory === "salespartner"
                      ? "1px solid #D2538C"
                      : "",
                  fontSize: "15px",
                  fontWeight: "bold",
                  letterSpacing: "0.5px",
                }}
              >
                Sales Partner
              </span>
            </a>

            {/* <a
              className="cs_appointment_category"
              style={{
                backgroundImage:
                  "linear-gradient(134deg, #D2538C 0%, #F5B63E 100%)",
                color: "white",
                padding: "9px 20px",
                marginTop: "-9px",
                borderRadius: "10pc",
              }}
              onClick={() => logout()}
            >
              <span
                style={{
                  fontSize: "14px",
                  fontWeight: "100",
                  letterSpacing: "0.5px",
                }}
              >
                Logout
              </span>
            </a> */}
          </div>

          {(activeCategory === "subscriptions" ||
            activeCategory === "tickets") && (
              <button
                type="button" // <-- Change type to 'button' to prevent form submission
                className="cs_btn cs_style_1"
                onClick={() => {
                  if (activeCategory === "tickets") {
                    handleNewTicketClick();
                  }
                  if (activeCategory === "subscriptions") {
                    navigate("/onboarding");
                  }
                }}
              >
                <span>
                  {activeCategory === "subscriptions"
                    ? "New Subscription"
                    : "New Ticket"}
                </span>
                <i>
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  <img src="/images/icons/arrow_white.svg" alt="Icon" />
                </i>
              </button>
            )}
        </div>
        {activeCategory === "profile" && (
          <>
            <div
              className="cs_appointment_form_fields"
              style={{ justifyContent: "unset" }}
            >
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Name</label>
                  <input
                    type="text"
                    value={name}
                    readOnly={true}
                    onChange={(e) => setName(e.target.value)}
                    className="bg-white"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Phone Number</label>
                  <input
                    type="text"
                    value={phone}
                    onChange={(e) => setPhone(e.target.value)}
                    className="bg-white"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Email</label>
                  <input
                    type="text"
                    value={email}
                    onChange={(e) => setEmail(e.target.value)}
                    className="bg-white"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Gender</label>
                  <input
                    type="text"
                    value={gender}
                    readOnly={true}
                    onChange={(e) => setGender(e.target.value)}
                    className="bg-white"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Date of Birth</label>
                  <input
                    type="text"
                    value={dob}
                    readOnly={true}
                    onChange={handleDobChange}
                    className="bg-white"
                    placeholder="DD/MM/YYYY"
                    maxLength="10"
                  />
                </div>
              </div>
              <div className="cs_appointment_form_field">
                <div className="cs_appointment_form_field_right">
                  <label>Address</label>
                  <textarea className="bg-white" readOnly={true} placeholder="Address">
                    {address}
                  </textarea>
                </div>
              </div>
              <div className="flex flex-col">
                <button
                  type="button"
                  className="cs_btn cs_style_1"
                  onClick={() => handleProfileUpdate()}
                >
                  <span>Update Profile</span>
                  <i>
                    <img src="/images/icons/arrow_white.svg" alt="Icon" />
                    <img src="/images/icons/arrow_white.svg" alt="Icon" />
                  </i>
                </button>
                {updated && (
                  <div className="cs_appointment_form_field_right ">
                    Profile updated successfully !!
                  </div>
                )}
              </div>
            </div>
            <h6 style={{ marginTop: '50px' }}>Disclaimer: For any changes to the details provided, except Email ID and phone number, please email us at info@getafixmd.com or raise a ticket. Ensure you provide a valid government ID proof to support your request. This is to ensure that the changes are properly recorded and do not cause any issues during claims.</h6>
          </>
        )}
        {activeCategory === "family" && (
          <>
            <div
              style={{
                display: "grid",
                gridTemplateColumns: "4fr 4fr 4fr", // Added column for status
                gap: "20px",
                margin: "-10px",
                color: "#D2538C", // Pink color for content
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                Name
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                DOB
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                Gender
              </div>

              <>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondAdult?.name : JSON.parse(user?.family_detials)?.secondAdult?.name}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondAdult?.dob : JSON.parse(user?.family_detials)?.secondAdult?.dob}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondAdult?.gender : JSON.parse(user?.family_detials)?.secondAdult?.gender}
                </div>
              </>
              <>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.firstChild?.name : JSON.parse(user?.family_detials)?.firstChild?.name}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.firstChild?.dob : JSON.parse(user?.family_detials)?.firstChild?.dob}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.firstChild?.gender : JSON.parse(user?.family_detials)?.firstChild?.gender}
                </div>
              </>
              <>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondChild?.name : JSON.parse(user?.family_detials)?.secondChild?.name}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondChild?.dob : JSON.parse(user?.family_detials)?.secondChild?.dob}
                </div>
                <div
                  style={{
                    padding: "10px 10px 10px 0px",
                    backgroundColor: "#fff",
                    color: "#D2538C", // Pink color for content
                  }}
                >
                  {typeof (user?.family_detials) == 'object' ? user?.family_detials?.secondChild?.gender : JSON.parse(user?.family_detials)?.secondChild?.gender}
                </div>
              </>
            </div>
            <h6 style={{ marginTop: '50px' }}>Disclaimer: For any changes to the details provided, please email us at info@getafixmd.com or raise a ticket. Ensure you provide a valid government ID proof to support your request. This is to ensure that the changes are properly recorded and do not cause any issues during claims.</h6>
          </>
        )}
        {activeCategory === "subscriptions" && (
          <div>
            <AwardSectionStyle2
              sectionTitle="Subscriptions"
              sectionSubTitle="Thank you for choosing GetAFixMD. <br/> We are at your service from now on."
              data={bookings?.data}
            />
          </div>
        )}
        {activeCategory === "tickets" && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 2fr 3fr 1fr", // Added column for status
              gap: "20px",
              margin: "-10px",
              color: "#D2538C", // Pink color for content
            }}
          >
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                textDecoration: "underline",
                color: "#D2538C", // Bright pink color for heading
              }}
            >
              Date
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                textDecoration: "underline",
                color: "#D2538C", // Bright pink color for heading
              }}
            >
              Subject
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                textDecoration: "underline",
                color: "#D2538C", // Bright pink color for heading
              }}
            >
              Message
            </div>
            <div
              style={{
                fontWeight: "bold",
                marginBottom: "10px",
                textDecoration: "underline",
                color: "#D2538C", // Bright pink color for heading
              }}
            >
              Status
            </div>

            {tickets.length > 0 ? (
              tickets.map((ticket) => (
                <>
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      color: "#D2538C", // Pink color for content
                    }}
                  >
                    {new Date(ticket.created_at).toLocaleDateString()}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      color: "#D2538C", // Pink color for content
                    }}
                  >
                    {ticket.subject}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      color: "#D2538C", // Pink color for content
                    }}
                  >
                    {ticket.message}
                  </div>
                  <div
                    style={{
                      padding: "10px",
                      backgroundColor: "#fff",
                      color: "#D2538C", // Pink color for content
                    }}
                  >
                    {ticket.status}
                  </div>
                </>
              ))
            ) : (
              <p>No tickets found.</p>
            )}
          </div>
        )}
        {activeCategory === "salespartner" &&
          (user.partner_details ? (
            <div
              style={{
                display: "grid",
                // gridTemplateColumns: "1fr 2fr 3fr 1fr 50px", // Added column for status and call icon
                gap: "20px",
                margin: "-10px",
                color: "#D2538C", // Pink color for content
              }}
            >
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  // textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                Sales partner Name: {user.partner_details.name}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  // textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                Sales partner Email: {user.partner_details.email}
              </div>
              <div
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  // textDecoration: "underline",
                  color: "#D2538C", // Bright pink color for heading
                }}
              >
                Sales partner Phone: {user.partner_details.phone}
              </div>
              <div style={{ display: "flex" }}>
                <a
                  href={`tel:${user.partner_details.phone}`}
                  style={{
                    display: "flex",
                    alignItems: "center",
                    color: "#D2538C", // Matching color for the icon
                    textDecoration: "none",
                  }}
                >
                  <img
                    src="/images/call-svgrepo-com.svg"
                    style={{ width: "39px" }}
                  />
                </a>
                <a
                  href={`https://api.whatsapp.com/send?phone=${phoneNumber}&text=Hello`}
                  target="_blank" // Opens the link in a new tab
                  rel="noopener noreferrer" // Security best practice for opening new tabs
                  style={{
                    display: "flex",
                    alignItems: "center",
                    textDecoration: "none", // Remove underline from the link
                  }}
                >
                  <FaWhatsapp
                    style={{
                      height: "50px",
                      marginLeft: "10px",
                      width: "30px",
                    }}
                  />
                </a>
              </div>
            </div>
          ) : (
            "No Sales Partner"
          ))}
      </form>

      {/* Modal */}
      {showModal && (
        <div
          className="modal"
          style={{
            position: "fixed",
            zIndex: 1000, // Ensure it's on top
            left: 0,
            top: 0,
            width: "100%",
            height: "100%",
            overflow: "auto",
            backgroundColor: "rgba(0,0,0,0.8)", // Darker background for visibility
            display: "flex", // Center the modal
            alignItems: "center", // Vertical center
            justifyContent: "center", // Horizontal center
          }}
        >
          <div
            className="modal-content"
            style={{
              backgroundColor: "#fff", // White background for modal content
              padding: "20px",
              border: "1px solid #888",
              width: "80%",
              maxWidth: "500px",
              borderRadius: "8px",
              boxShadow: "0px 0px 10px rgba(0, 0, 0, 0.5)", // Add a shadow for depth
            }}
          >
            {/* <span
        className="close"
        onClick={handleCloseModal}
        style={{
          color: "#aaa",
          float: "right",
          fontSize: "28px",
          fontWeight: "bold",
          cursor: "pointer",
        }}
      >
        &times;
      </span> */}
            <form
              onSubmit={(e) => {
                e.preventDefault(); // Prevent page refresh
                handleSubmitTicket(subject, message, status);
                handleCloseModal(); // Close the modal after submitting
              }}
            >
              <div>
                <label>Subject</label>
                <input
                  type="text"
                  value={subject}
                  onChange={(e) => setSubject(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "8px 0",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                  }}
                />
              </div>
              <div>
                <label>Message</label>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  required
                  style={{
                    width: "100%",
                    padding: "10px",
                    margin: "8px 0",
                    boxSizing: "border-box",
                    borderRadius: "4px",
                    border: "1px solid #ccc",
                    minHeight: "100px",
                  }}
                ></textarea>
              </div>
              <div style={{ display: "flex", gap: "10px" }}>
                <button
                  type="submit"
                  className="cs_btn cs_style_1"
                  style={{
                    backgroundColor: "#D2538C",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <span>Submit Ticket</span>
                </button>

                <button
                  onClick={handleCloseModal}
                  className="cs_btn cs_style_1"
                  style={{
                    backgroundColor: "#D2538C",
                    color: "#fff",
                    padding: "10px 20px",
                    border: "none",
                    borderRadius: "4px",
                    cursor: "pointer",
                  }}
                >
                  <span>Close</span>
                </button>
              </div>
            </form>
          </div>
        </div>
      )}

      <style>
        {`
  @media (max-width: 1200px) {
    .ticket-list > div {
      flex: 1 1 calc(50% - 20px); /* Two items per row on medium screens */
    }
  }

  @media (max-width: 768px) {
    .ticket-list > div {
      flex: 1 1 calc(100% - 20px); /* One item per row on small screens */
    }
  }
`}
      </style>
    </div>
  );
}
