import { useSelector } from 'react-redux';
import PricingSection from '../Section/PricingSection';
import { getPlans } from '../../redux/user/userSlice';
import useAuthToken from '../../hooks/useAuthToken';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import Spacing from '../Spacing';
import DepartmentSectionStyle2 from '../Section/DepartmentSection/DepartmentSectionStyle2';

const departmentData = [
    {
        title: '24/7 Specialist Access',
        subTitle:
            'Unlimited online consultations with a wide range of healthcare professionals.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Unlimited In-Person Consults',
        subTitle:
            'Free, unlimited face-to-face doctor visits across various specialties.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Dedicated Family Doctor',
        subTitle:
            'Personalized care from a chosen family doctor for tailored healthcare.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Exclusive Wellness Programs',
        subTitle:
            'Access to curated health programs, including early disease detection and wellness advice.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
    {
        title: 'Extensive Insurance Coverage',
        subTitle:
            'Comprehensive health and accident insurance with added benefits and coverage.',
        iconUrl: '/images/icons/calendar_white.svg'
    },
];

export default function StepPlan({ back, goToPreNominee }) {
    const plans = useSelector(state => state.user.plans);
    const dispatch = useDispatch();
    const token = useAuthToken();

    useEffect(() => {
        dispatch(getPlans(token))
    }, [])

    return (
        <>
            <PricingSection
                sectionTitle="Choose Your GetAFixMD Plan"
                plans={plans?.data}
                back={back}
                goToPreNominee={goToPreNominee}
            />
            <Spacing md="200" lg="200" xl="200" />
            <DepartmentSectionStyle2
                sectionTitle="Your Benefits"
                sectionTitleUp="BENEFITS"
                data={departmentData}
            />
        </>
    );
}