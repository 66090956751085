import React, { useState } from "react";
import { Icon } from "@iconify/react";
import { WEBSITE_URL } from "../../config/apiUrls";
import { Modal } from "antd";

export default function IconBoxStyle5({ item }) {

  const [showPopup, setShowPopup] = useState(false);
  const [data, setData] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

  return (
    <div className="cs_iconbox cs_style_5 cs_white_bg cs_radius_15">
      <div className="cs_iconbox_icon cs_center cs_accent_bg cs_radius_15">
        <img src={"/images/icons/award.svg"} alt="Icon" />
      </div>
      <h2 className="cs_iconbox_title m-0 cs_fs_18 cs_medium">
        {item?.unique_id}
        <br />
        <span style={{ fontSize: "12px" }}> {item?.plan?.name}</span> <br />
        {item?.payment_status == "SUCCESS" && (
          <span style={{ fontSize: "12px" }}>
            End Date: {item?.end_date}
            <br />
          </span>
        )}
        <br />
        {item?.payment_status != "SUCCESS" && (
          <h6 className="text-danger">Payment Failed</h6>
        )}
        <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'center' }}>
          <div className={`${isMobile ? "col-6" : ""}`}>
            {item?.zoho_invoice_id ? (
              <a
                target="_blank"
                href={WEBSITE_URL + "zoho/invoices/print/" + item?.zoho_invoice_id}
                style={{
                  border: "1px solid #D2538C",
                  padding: "5px 10px",
                  borderRadius: "10px",
                  fontSize: '15px'
                }}
              >
                <span>View</span>{" "}
                <Icon icon="ant-design:eye-twotone" style={{ fontSize: "20px" }} />
              </a>
            ) : (
              <div style={{ fontSize: "12px" }}>Not Generated</div>
            )}
          </div>
          <div className={`${isMobile ? "col-6" : ""}`}
            onClick={() => {
              setShowPopup(true);
              setData(item?.plan?.all_benefits);
            }}
            style={{
              border: "1px solid #D2538C",
              padding: "5px 10px",
              borderRadius: "10px",
              fontSize: '12px',
              marginLeft: '5px'
            }}
          ><Icon icon="ant-design:unordered-list-outlined" style={{ fontSize: "18px" }} /> View Benefits</div>
        </div>
      </h2>

      <Modal
        visible={showPopup}
        onOk={() => setShowPopup(false)}
        onCancel={() => setShowPopup(false)}
        footer={null}
      >
        <div>
          {data && data.length > 0 && data.map((feature) => (
            <p>{feature}</p>
          ))}
        </div>
      </Modal>
    </div>
  );
}
